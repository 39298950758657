import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-40913456")
const _hoisted_1 = {
  key: 0,
  class: "spinner__container"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ScrollTop = _resolveComponent("ScrollTop")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Toast),
    _createVNode(_component_Toast, {
      group: "top-left",
      position: "top-left"
    }),
    _createVNode(_component_ConfirmDialog),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ProgressSpinner, {
            style: {"width":"50px","height":"50px"},
            strokeWidth: "8",
            animationDuration: ".5s"
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    _createVNode(_component_ScrollTop)
  ], 64))
}