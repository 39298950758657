import { App, reactive } from 'vue';

// COMPONENTS
import PrimeVue                 from 'primevue/config';
import AutoComplete             from 'primevue/autocomplete';
import Accordion                from 'primevue/accordion';
import AccordionTab             from 'primevue/accordiontab';
import Avatar                   from 'primevue/avatar';
import AvatarGroup              from 'primevue/avatargroup';
import Badge                    from 'primevue/badge';
import BlockUI                  from 'primevue/blockui';
import Button                   from 'primevue/button';
import Breadcrumb               from 'primevue/breadcrumb';
import Calendar                 from 'primevue/calendar';
import Card                     from 'primevue/card';
import CascadeSelect            from 'primevue/cascadeselect';
import Carousel                 from 'primevue/carousel';
import Chart                    from 'primevue/chart';
import Checkbox                 from 'primevue/checkbox';
import Chip                     from 'primevue/chip';
import Chips                    from 'primevue/chips';
import ColorPicker              from 'primevue/colorpicker';
import Column                   from 'primevue/column';
import ConfirmDialog            from 'primevue/confirmdialog';
import ConfirmPopup             from 'primevue/confirmpopup';
import ContextMenu              from 'primevue/contextmenu';
import DataTable                from 'primevue/datatable';
import DataView                 from 'primevue/dataview';
import DataViewLayoutOptions    from 'primevue/dataviewlayoutoptions';
import Dialog                   from 'primevue/dialog';
import Divider                  from 'primevue/divider';
import Dropdown                 from 'primevue/dropdown';
import Fieldset                 from 'primevue/fieldset';
import FileUpload               from 'primevue/fileupload';
import Galleria                 from 'primevue/galleria';
import InlineMessage            from 'primevue/inlinemessage';
import Inplace                  from 'primevue/inplace';
import InputSwitch              from 'primevue/inputswitch';
import InputText                from 'primevue/inputtext';
import InputMask                from 'primevue/inputmask';
import InputNumber              from 'primevue/inputnumber';
import Knob                     from 'primevue/knob';
import Listbox                  from 'primevue/listbox';
import MegaMenu                 from 'primevue/megamenu';
import Menu                     from 'primevue/menu';
import Menubar                  from 'primevue/menubar';
import Message                  from 'primevue/message';
import MultiSelect              from 'primevue/multiselect';
import OrderList                from 'primevue/orderlist';
import OrganizationChart        from 'primevue/organizationchart';
import OverlayPanel             from 'primevue/overlaypanel';
import Paginator                from 'primevue/paginator';
import Panel                    from 'primevue/panel';
import PanelMenu                from 'primevue/panelmenu';
import Password                 from 'primevue/password';
import PickList                 from 'primevue/picklist';
import ProgressBar              from 'primevue/progressbar';
import ProgressSpinner          from 'primevue/progressspinner';
import Rating                   from 'primevue/rating';
import RadioButton              from 'primevue/radiobutton';
import SelectButton             from 'primevue/selectbutton';
import ScrollPanel              from 'primevue/scrollpanel';
import ScrollTop                from 'primevue/scrolltop';
import Skeleton                 from 'primevue/skeleton';
import Slider                   from 'primevue/slider';
import Sidebar                  from 'primevue/sidebar';
import SplitButton              from 'primevue/splitbutton';
import Splitter                 from 'primevue/splitter';
import SplitterPanel            from 'primevue/splitterpanel';
import Steps                    from 'primevue/steps';
import TabMenu                  from 'primevue/tabmenu';
import TieredMenu               from 'primevue/tieredmenu';
import Textarea                 from 'primevue/textarea';
import Toast                    from 'primevue/toast';
import Toolbar                  from 'primevue/toolbar';
import TabView                  from 'primevue/tabview';
import TabPanel                 from 'primevue/tabpanel';
import Tag                      from 'primevue/tag';
import Timeline                 from 'primevue/timeline';
import ToggleButton             from 'primevue/togglebutton';
import Tree                     from 'primevue/tree';
import TreeTable                from 'primevue/treetable';
import TriStateCheckbox         from 'primevue/tristatecheckbox';

// SERVICES
import ToastService             from 'primevue/toastservice';
import ConfirmationService      from 'primevue/confirmationservice';

// DIRECTIVES
import BadgeDirective   from "primevue/badgedirective";
import Ripple           from 'primevue/ripple';
import Tooltip          from 'primevue/tooltip';

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

export function initPrimeVue(app: App){
    
    app.config.globalProperties.$appState = reactive({ inputStyle: 'outlined' });
        
    app.use(PrimeVue, { 
        ripple: true,
        // zIndex: {
        //     modal: 1100,        //dialog, sidebar
        //     overlay: 1000,      //dropdown, overlaypanel
        //     menu: 1000,         //overlay menus
        //     tooltip: 1100       //tooltip
        // }
    });
    app.use(ToastService);
    app.use(ConfirmationService);

    app.directive('badge',      BadgeDirective);
    app.directive('ripple',     Ripple);
    app.directive('tooltip',    Tooltip);

    app.component('Accordion',          Accordion);
    app.component('AccordionTab',       AccordionTab);
    app.component('AutoComplete',       AutoComplete);
    app.component('Avatar',             Avatar);
    app.component('AvatarGroup',        AvatarGroup);
    app.component('Badge',              Badge);
    app.component('BlockUI',            BlockUI);
    app.component('Breadcrumb',         Breadcrumb);
    app.component('Button',             Button);
    app.component('Calendar',           Calendar);
    app.component('Card',               Card);
    app.component('Carousel',           Carousel);
    app.component('CascadeSelect',      CascadeSelect);
    app.component('Chart',              Chart);
    app.component('Checkbox',           Checkbox);
    app.component('Chip',               Chip);
    app.component('Chips',              Chips);
    app.component('ColorPicker',        ColorPicker);
    app.component('Column',             Column);
    app.component('ConfirmDialog',      ConfirmDialog);
    app.component('ConfirmPopup',       ConfirmPopup);
    app.component('ContextMenu',        ContextMenu);
    app.component('DataTable',          DataTable);
    app.component('DataView',           DataView);
    app.component('PDialog',            Dialog);
    app.component('Divider',            Divider);
    app.component('Dropdown',           Dropdown);
    app.component('Fieldset',           Fieldset);
    app.component('FileUpload',         FileUpload);
    app.component('InlineMessage',      InlineMessage);
    app.component('Inplace',            Inplace);
    app.component('InputMask',          InputMask);
    app.component('InputNumber',        InputNumber);
    app.component('InputSwitch',        InputSwitch);
    app.component('InputText',          InputText);
    app.component('Galleria',           Galleria);
    app.component('Knob',               Knob);
    app.component('Listbox',            Listbox);
    app.component('MegaMenu',           MegaMenu);
    app.component('Menu',               Menu);
    app.component('Menubar',            Menubar);
    app.component('Message',            Message);
    app.component('MultiSelect',        MultiSelect);
    app.component('OrderList',          OrderList);
    app.component('OverlayPanel',       OverlayPanel);
    app.component('Paginator',          Paginator);
    app.component('Panel',              Panel);
    app.component('PanelMenu',          PanelMenu);
    app.component('Password',           Password);
    app.component('PickList',           PickList);
    app.component('ProgressBar',        ProgressBar);
    app.component('ProgressSpinner',    ProgressSpinner);
    app.component('RadioButton',        RadioButton);
    app.component('Rating',             Rating);
    app.component('SelectButton',       SelectButton);
    app.component('ScrollPanel',        ScrollPanel);
    app.component('ScrollTop',          ScrollTop);
    app.component('Slider',             Slider);
    app.component('Sidebar',            Sidebar);
    app.component('Skeleton',           Skeleton);
    app.component('SplitButton',        SplitButton);
    app.component('Splitter',           Splitter);
    app.component('SplitterPanel',      SplitterPanel);
    app.component('Steps',              Steps);
    app.component('TabMenu',            TabMenu);
    app.component('TabView',            TabView);
    app.component('TabPanel',           TabPanel);
    app.component('Tag',                Tag);
    app.component('Textarea',           Textarea);
    app.component('TieredMenu',         TieredMenu);
    app.component('Timeline',           Timeline);
    app.component('Toast',              Toast);
    app.component('Toolbar',            Toolbar);
    app.component('ToggleButton',       ToggleButton);
    app.component('Tree',               Tree);
    app.component('TreeTable',          TreeTable);
    app.component('TriStateCheckbox',   TriStateCheckbox);
    app.component('OrganizationChart',  OrganizationChart);
    app.component('DataViewLayoutOptions',  DataViewLayoutOptions);
}

export interface MenuItem {
    label     ?: string;
    icon      ?: string;
    to        ?: string | any;
    command   ?: Function;
    url       ?: string;
    items     ?: Array<any>;
    disabled  ?: boolean;
    visible   ?: boolean | Function;
    target    ?: string;
    separator ?: boolean;
    style     ?: Object;
    class     ?: string | Function;
    key       ?: string;
}