import { App, Plugin } from 'vue';

import { ModuleOptions } from '@/modules/common/ModuleOptions';

import { routes } from './router';

export const ZonesModule: Plugin = {
    install(app: App<Element>, { router, parentRoute }: ModuleOptions) {
        console.groupCollapsed('ZONES_MODULE: ADDING ROUTES');

        if (parentRoute) {
            routes.forEach((route) => {
                console.debug("Adding route to %s", parentRoute, route);
                router.addRoute(parentRoute, route);
            });
        } else {
            routes.forEach((route) => {
                console.debug("Adding route", route);
                router.addRoute(route);
            });
        }
        
        console.debug("After:", router.getRoutes());
        console.groupEnd();
    }
};
