import { AccessesBySection, UserRoleEnum } from '@/model/enums/UserRoleEnum';
import { RouteRecordRaw } from 'vue-router';
import {ActivitiesRoutesEnum} from "@/modules/activities/router/ActivitiesRoutesEnum";
import {TicketsRoutesEnum} from "@/modules/tickets/router";

const ActivitiesPage   = () => import(/* webpackChunkName: "Projects-module" */ '../pages/activities/ActivitiesPage.vue');
const ActivityPage   = () => import(/* webpackChunkName: "Projects-module" */ '../pages/activity/ActivityPage.vue');
const ActivityData   = () => import(/* webpackChunkName: "Projects-module" */ '../pages/activity/ActivityData/ActivityData.vue');
const ActivityKPI   = () => import(/* webpackChunkName: "Projects-module" */ '../pages/activity/ActivityKPI/ActivityKPI.vue');
const ActivityShifts   = () => import(/* webpackChunkName: "Projects-module" */ '../pages/activity/ActivityShifts/ActivityShifts.vue');
const ActivityTickets   = () => import(/* webpackChunkName: "Projects-module" */ '../pages/activity/ActivityTickets/ActivityTickets.vue');

const routes:  Array<RouteRecordRaw> = [
    {
        path: '/activities',
        name: ActivitiesRoutesEnum.ACTIVITY_LIST,
        component: ActivitiesPage,
        meta	 : {
            tabTitle: 'Activity List',
            breadCrumbItems: [
                { label: 'Activity List' },
            ],
            roles: AccessesBySection.ACVIVITIES
        }
    },
    {
        path: '/activities/:activityId',
        props: true,
        component: ActivityPage,
        meta: {
            breadCrumbItems: [
                {
                    label: 'Activities',
                    to: {name: ActivitiesRoutesEnum.ACTIVITY_LIST}
                },
                {label: 'Activity details'},
            ],
            roles: AccessesBySection.ACVIVITIES
        },
        children: [
            {
                path      : '',
                name: ActivitiesRoutesEnum.ACTIVITY_DETAIL,
                component : ActivityData,
                meta: {
                    tabTitle: 'Activity Sheet'
                }
            },
            {
                path      : 'kpi',
                name: ActivitiesRoutesEnum.ACTIVITY_KPI,
                component : ActivityKPI,
                meta: {
                    tabTitle: 'Activity KPI'
                }
            },
            {
                path      : 'tickets',
                name: ActivitiesRoutesEnum.ACTIVITY_TICKETS,
                props: true,
                component : ActivityTickets,
                meta: {
                    tabTitle: 'Activity Tickets'
                }
            },
            {
                path      : 'shifts',
                name: ActivitiesRoutesEnum.ACTIVITY_SHIFTS,
                component : ActivityShifts,
                meta: {
                    tabTitle: 'Activity Shifts'
                }
            },
        ]
    }
];

export { routes };
export { ActivitiesRoutesEnum };
