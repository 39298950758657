export enum UserRoleEnum {
    SUPER_ADMIN            = 100,
    CUSTOMER               = 30,
    BROKER                 = 40,
    FOREMAN                = 31,
    DRIVER_OWNER_OPERATOR  = 20,
    DRIVER                 = 10,
    CLIENT                 = 50,
    PM                     = 51,
    ACCOUNT                = 52,
}

export const RolesUserCanSee = {
    30: [
        UserRoleEnum.FOREMAN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.ACCOUNT,
        UserRoleEnum.PM
    ],
}

export const AccessesBySection = {
    DASHBOARD: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.DRIVER,
        UserRoleEnum.DRIVER_OWNER_OPERATOR,
        UserRoleEnum.CLIENT,
        UserRoleEnum.PM,
        UserRoleEnum.FOREMAN,
        UserRoleEnum.BROKER
    ],
    ADMIN: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER
    ],
    DRIVER: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.CLIENT,
        UserRoleEnum.BROKER
    ],
    BROKERS: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.CLIENT
    ],
    CUSTOMERS: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.CLIENT
    ],
    CLIENTS: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER
    ],
    SMART_ZONES: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.CLIENT
    ],
    CONTRACTS: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.CLIENT
    ],
    MATERIALS: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.CLIENT
    ],
    APP_WRS: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.CLIENT
    ],
    WRS: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.CLIENT,
        UserRoleEnum.PM,
        UserRoleEnum.FOREMAN,
        UserRoleEnum.BROKER
    ],
    WRS_EDIT: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.CLIENT
    ],
    TICKETS: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.CLIENT,
        UserRoleEnum.ACCOUNT,
        UserRoleEnum.BROKER,
        UserRoleEnum.DRIVER
    ],
    TICKET_EDIT: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.CLIENT
    ],
    WRS_DRIVERS: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.CLIENT,
        UserRoleEnum.BROKER
    ],
    LEDGER_MOVEMENTS: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.CLIENT,
        UserRoleEnum.ACCOUNT
    ],
    LEDGERS: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.CLIENT,
        UserRoleEnum.PM,
        UserRoleEnum.ACCOUNT,
        UserRoleEnum.BROKER
    ],
    NOTIFICATION_CENTER: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.CLIENT
    ],
    LIVE_VIEW: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.CLIENT,
        UserRoleEnum.PM,
        UserRoleEnum.FOREMAN,
        UserRoleEnum.BROKER
    ],
    SEND_TEXT: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.CLIENT,
        UserRoleEnum.PM
    ],
    TEXT_LIST: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.CLIENT,
        UserRoleEnum.PM
    ],
    PROJECTS: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.CLIENT
    ],
    ACVIVITIES: [
        UserRoleEnum.SUPER_ADMIN,
        UserRoleEnum.CUSTOMER,
        UserRoleEnum.CLIENT
    ]
}
