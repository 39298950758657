import { Plugin } from 'vue';

import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";

/*
    TODO: I18n messaggi default 
*/

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        /**
         * Success message.   
         * In generally a green toast
         */
        readonly $successMessage: (detail: string, summary?: string) => void;
        // TODO: Sostituirà $successMessage
        readonly $successMessageG: (config: {
            detail: string,
            summary?: string,
            group: string,
        }) => Promise<boolean>;
        /**
         * Error message.  
         * In generally a red toast 
         */
        readonly $errorMessage: (detail: string, summary?: string) => void;


        readonly $confirmMessage: (detail: string, summary?: string) => Promise<boolean>;

        // TODO: Sostituirà $confirmMessage
        readonly $confirmMessageG: (config: {
            message: string,
            header?: string,
            group: string,
        }) => Promise<boolean>;
    }
}

const MessagesPlugin: Plugin = {
    install(app) {
        console.debug("Installing messages plugin...");

        app.config.globalProperties.$successMessage =
            function (detail: string, summary: string = "Completed") {
                this.$toast.add({
                    severity: 'success',
                    summary,
                    detail,
                    life: 3000
                });
            };

        app.config.globalProperties.$successMessageG =
            function (data) {
                data.summary ??= "Completed";
                const {summary, detail, group} = data;

                this.$toast.add({
                    group,
                    severity: 'success',
                    summary,
                    detail,
                    life: 3000
                });
            };

        app.config.globalProperties.$errorMessage =
            function (detail: string, summary: string = "Aborted") {
                this.$toast.add({
                    severity: 'error',
                    summary,
                    detail,
                    life: 3000
                });
            };


        app.config.globalProperties.$confirmMessage =
            function (
                message: string,
                header: string = "Confirmation requested",
            ) {
                return new Promise((resolve) => {

                    this.$confirm.require({
                        message, header,
                        icon: 'pi pi-exclamation-triangle',
                        acceptClass: 'p-button-danger',
                        accept: () => {
                            resolve(true);
                        },
                        reject: () => {
                            resolve(false);
                        }
                    });

                });

            }

        // TODO: Sostituirà $confirmMessage 
        app.config.globalProperties.$confirmMessageG =
            function (config: {
                message: string,
                header?: string,
                group: string,
            }) {
                return new Promise((resolve) => {
                    config.header ??= "Confirmation requested";

                    const { message, header, group } = config;


                    this.$confirm.require({
                        group, message, header,
                        icon: 'pi pi-exclamation-triangle',
                        acceptClass: 'p-button-danger',
                        accept: () => {
                            resolve(true);
                        },
                        reject: () => {
                            resolve(false);
                        }
                    });

                });
            }
    }
};

export default MessagesPlugin;

export function useMessages() {
    const {
        add
    } = useToast();

    const {
        require
    } = useConfirm();


    function successMessage(
        detail: string,
        summary: string = "Completed"
    ) {
        add({
            severity: 'success',
            summary,
            detail,
            life: 3000
        });
    }

    function errorMessage(
        detail: string,
        summary: string = "Aborted"
    ) {
        add({
            severity: 'error',
            summary,
            detail,
            life: 3000
        });
    }

    function errorMessageLeft(
        detail: string,
        summary: string = "Aborted"
    ) {
        add({
            severity: 'error',
            summary,
            detail,
            life: 3000,
            group: 'top-left'
        });
    }

    function confirmMessage(
        message: string,
        header: string = "Confirmation requested"
    ) {
        return new Promise(
            (resolve) => {
                require({
                    message, header,
                    icon: 'pi pi-exclamation-triangle',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        resolve(true);
                    },
                    reject: () => {
                        resolve(false);
                    }
                });
            }
        );
    }

    return {
        successMessage,
        errorMessage,
        errorMessageLeft,
        confirmMessage
    }
}
