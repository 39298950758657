
  import { defineComponent } from "vue";

  export default defineComponent({
    props: ["modelValue"],
    computed: {
      formatted() {
        if (typeof this.modelValue === "number") {
            return `$ ${(this as any).modelValue?.toFixed(2)}`
        } else {
            return "$ -";
        }
      },
    },
  });
