
  import { defineComponent } from "vue";

  export default defineComponent({
    computed: {
      isLoading() {
        return this.$config.isLoading; 
      }
    }
  });
