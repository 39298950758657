import { configuration } from "@plugins/Config-plugin";
import Pusher  from "pusher-js";
import {ref} from "vue";

Pusher.logToConsole = process.env.NODE_ENV !== "production";

export const $pusher = ref<Pusher>( null );

export function createPusher( token: string ) {
    if (!token) {
        throw("No token provided");
    }

    if (!$pusher.value) {
        console.debug( "Connect pusher instance" );

        const p = new Pusher( configuration.pusherKey, {
            cluster     : configuration.pusherCluster,
            authEndpoint: `${ configuration.apiBaseUrl }/broadcasting/auth`,
            auth        : {
                headers: {
                    "Authorization": `Bearer ${ token }`
                }
            },
        } )

        $pusher.value = p;

        handleLimitError( p );
    }
}

export function resetPusher() {
    $pusher.value.unbind_all();
    $pusher.value.disconnect();
    $pusher.value = null;
}

export function subscribeChannel( channel: Channel ) {
    const c = $pusher.value.subscribe( `private-${ channel }` )

    c.bind( "pusher:subscription_succeeded", () => {
        console.info( `Subscribed to channel: ${ channel }` );
    } )

    c.bind( "pusher:subscription_error", ( error ) => {
        console.error( `Subscription error for channel:  ${ channel }`, error );
    } )

    return c;
}

function handleLimitError( pusher: Pusher ) {
    pusher.connection.bind( "error", ( error ) => {
        if (error?.data.code === 4004) {
            console.error( 'Pusher connection limit reached.' );
        } else {
            console.error( error?.data?.message );
        }

    } )
}

type Channel = PusherChannelEnum | string;



export const enum PusherChannelEnum {
    CHAT_ADMIN = "notification_center.admin",
    CHAT_CUSTOMER = "notification_center.customer",
    CHAT_BROKER = "notification_center.broker",
}

export const enum PusherEventsEnum {
    READ = "read",
    READ_ALL  = "read-all",
    NEW  = "new",
}
