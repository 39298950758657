import {AccessesBySection, UserRoleEnum} from '@/model/enums/UserRoleEnum';
import {RouteRecordRaw} from 'vue-router';
import {UsersRoutesEnum} from './UsersRoutesEnum';

const UsersPage 	= () => import(
	/* webpackChunkName: "Users-module" */ '../pages/users/UsersPage.vue');
const UserPage 		= () => import(
	/* webpackChunkName: "Users-module" */ '../pages/user/UserPage.vue');

const UserData 		= () => import(
	/* webpackChunkName: "Users-module" */ '../pages/user/userData/UserData.vue');

const UserInvoices 		= () => import(
	/* webpackChunkName: "Users-module" */ '../pages/user/userInvoices/UserInvoices.vue');

const AdminsPage	= () => import(
	/* webpackChunkName: "Users-module" */ '../pages/admins/AdminsPage.vue');
const AdminPage	 	= () => import(
	/* webpackChunkName: "Users-module" */ '../pages/admin/AdminPage.vue');

const WRSList	= () => import(
	/* webpackChunkName: "Users-module" */ '../pages/wrs/WRSList.vue');

const routes: Array<RouteRecordRaw> = [
	
	{
		path     : '/drivers',
		name     : UsersRoutesEnum.USERS_LIST,
		component: UsersPage,
		meta	 : {
			breadCrumbItems: [
				{label: 'Driver list'},
			],
			tabTitle: "Drivers",
			roles: AccessesBySection.DRIVER
		}
	},
	{
		path     : '/drivers/:userId',
		props    : true,
		component: UserPage,
		meta	 : {
			breadCrumbItems: [
				{label: 'Driver list', to: { name: UsersRoutesEnum.USERS_LIST }},
				{label: 'Driver detail'},
			],
			tabTitle: "Driver",
			roles: AccessesBySection.DRIVER
		},
		children: [
			{
				path      : '',
				name      : UsersRoutesEnum.USER_DETAIL,
				component : UserData,
			},
			{
				path      : 'invoices',
				name      : UsersRoutesEnum.USER_INVOICES,
				component : UserInvoices,
			},
		]
	},

	

	{
		path     : '/admins',
		name     : UsersRoutesEnum.ADMINS_LIST,
		component: AdminsPage,
		meta	 : {
			breadCrumbItems: [
				{label: 'Admin list'},
			],
			tabTitle: "Admin",
			roles: AccessesBySection.ADMIN
		}
	},
	{
		path     : '/admins/:userId',
		name     : UsersRoutesEnum.ADMINS_DETAIL,
		props    : true,
		component: AdminPage,
		meta	 : {
			breadCrumbItems: [
				{label: 'Admin list', to: { name: UsersRoutesEnum.ADMINS_LIST }},
				{label: 'Admin detail'},
			],
			tabTitle: "Admin",
			roles: AccessesBySection.ADMIN
		}
	},

	{
		path     : '/wrs-drivers',
		name     : UsersRoutesEnum.WRS_LIST,
		component: WRSList,
		meta	 : {
			breadCrumbItems: [
				{label: 'WRS drivers'},
			],
			tabTitle: "WRS drivers",
			roles: AccessesBySection.WRS_DRIVERS
		}
	},

];

export { routes };
export { UsersRoutesEnum };
