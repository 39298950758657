import { RouteRecordRaw } from 'vue-router';
import { LiveViewRoutesEnum } from './LiveViewRoutesEnum';
import {AccessesBySection} from "@/model/enums/UserRoleEnum";

const LiveViewPage  = () => import(/* webpackChunkName: "LiveView-module" */ '../pages/LiveView/LiveViewPage.vue');

const routes:  Array<RouteRecordRaw> = [
    {
        path: '/live-view',
        name: LiveViewRoutesEnum.PREFIX,
        component: LiveViewPage,
        meta: {
            tabTitle: 'Live view',
			breadCrumbItems: [
				{label: 'Live view'},
			],
            roles: AccessesBySection.LIVE_VIEW
        }
    },
];

export { routes };
export { LiveViewRoutesEnum };
