import { AuthRoutesEnum } from "@/modules/auth/router";
import { authStore } from "@/modules/auth/store";
import { configuration } from "@plugins/Config-plugin";
import { localStorageSVC } from "@plugins/LocalStorage-plugin";
import { Router } from "vue-router";
import {AccessesBySection} from "@/model/enums/UserRoleEnum";

export function guards(router: Router) {
    router.beforeEach((to, from, next) => {
        // check meta route
        if (to.matched.some(it => it.meta.requiresAuth)) {
            // se non loggato torna alla login
            if (!localStorageSVC.token) {
                next({ name: AuthRoutesEnum.SIGN_IN });
                return;
            }
        }
        if (to.matched.some(it => it.meta.requiresGuest)) {
            // se loggato va alla home
            if (localStorageSVC.token) {
                next({ name: 'home' });
                return;
            }
        }

        const roles = to.meta?.roles as Array<number>;
        if (roles?.length > 0) {
            const me = authStore.getters.me;
            if (!roles.includes(me.role)) {
                if(AccessesBySection.DASHBOARD.includes(me.role)){
                    next({ path: '/' });
                } else {
                    next({ path: '/invoices' });
                }
                return;
            }
        }

        next();
    });
}

// TODO: Localizzare title 
export function tabTitle(router: Router) {
    router.beforeEach((to, from, next) => {
        // set tab title 
        if (to.matched && to.matched.length > 0) {
            document.title = `${configuration.appTitle} - ${to.meta.tabTitle ?? ''}`;
        }

        next();
    });
}
