import { MutationTree } from 'vuex';
import { User } from '@/model/api/User';
import { AuthState } from './state';

export interface Mutations {
    setMe(user: User): void;
}

const mutations: MutationTree<AuthState> = {
    setMe(state, user: User) {
        state.me = user;
    },
}

export default mutations;