import { App } from 'vue';

import BackButton    from './BackButton/BackButton.vue';
import CurrencyText  from './CurrencyText/CurrencyText.vue';
import DateFormatted from './DateFormatted/DateFormatted.vue';
import FloatLabel    from './FloatLabel/FloatLabel.vue';
import TableSkeleton from './TableSkeleton/TableSkeleton.vue';

export function registerGlobalComponents(app: App){
    app.component('BackButton',     BackButton);
    app.component('FloatLabel',     FloatLabel);
    app.component('DateFormatted',  DateFormatted);
    app.component('CurrencyText',   CurrencyText);
    app.component('TableSkeleton',  TableSkeleton);
}