import { RouteRecordRaw } from 'vue-router';
import {InvoiceMovementsRoutesEnum} from "@/modules/invoice_movements/router/InvoicesRoutesEnum";
import {AccessesBySection} from '@/model/enums/UserRoleEnum';

const InvoiceMovementsPage  = () => import(/* webpackChunkName: "Invoice-movements-module" */ '../pages/invoices/InvoicesPage.vue');
const InvoiceMovementPage  = () => import(/* webpackChunkName: "Invoice-movements-module" */ '../pages/invoice/InvoicePage.vue');

const routes:  Array<RouteRecordRaw> = [
    {
        path: '/invoice-movements',
        name: InvoiceMovementsRoutesEnum.INVOICE_MOVEMENTS_LIST,
        component: InvoiceMovementsPage,
		meta	 : {
			tabTitle: "Invoice movements List",
			breadCrumbItems: [
				{label: 'Invoice movements List'},
			],
			roles: AccessesBySection.LEDGER_MOVEMENTS
		}
    },
	{
		path     : '/invoice-movements/:invoiceId',
		name     : InvoiceMovementsRoutesEnum.INVOICE_MOVEMENT_DETAIL,
		props    : true,
		component: InvoiceMovementPage,
		meta	 : {
			tabTitle: "Add Invoice movement",
			breadCrumbItems: [
				{label: 'Invoice movements List', to: { name: InvoiceMovementsRoutesEnum.INVOICE_MOVEMENTS_LIST }},
				{label: 'Add Invoice movement'},
			],
			roles: AccessesBySection.LEDGER_MOVEMENTS
		}
	},
];

export { routes };
export { InvoiceMovementsRoutesEnum };
