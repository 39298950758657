import {AccessesBySection, UserRoleEnum} from '@/model/enums/UserRoleEnum';
import { RouteRecordRaw } from 'vue-router';
import { ZonesRoutesEnum } from './ZonesRoutesEnum';

const SmartZonesPage = () => import(
    /* webpackChunkName: "zones-module" */
    '../pages/smartZones/SmartZonesPage.vue'
    );
const SmartZonePage  = () => import(
  /* webpackChunkName: "zones-module" */
  '../pages/smartZone/SmartZone.vue'
);

const routes:  Array<RouteRecordRaw> = [
    {
        path: '/smart-zones',
        name: ZonesRoutesEnum.SMART_ZONES,
        component: SmartZonesPage,
        meta	 : {
            tabTitle: 'Smart Zone List',
            breadCrumbItems: [
                { label: 'Smart Zone List' },
            ],
            roles: AccessesBySection.SMART_ZONES
        }
    },
    {
        path: '/smart-zones/:zoneId',
        name: ZonesRoutesEnum.SMART_DETAILS,
        props: true,
        component: SmartZonePage,
        meta	 : {
          tabTitle: 'Smart Zone Details',
          breadCrumbItems: [
            {label: 'Smart Zones List', to: { name: ZonesRoutesEnum.SMART_ZONES }},
            {label: 'Smart Zone Details'},
          ],
          roles: AccessesBySection.SMART_ZONES
        }
    }
];

export { routes };
export { ZonesRoutesEnum };
