/**
 * Filter an array of object, looking for match to "query" in some fields
 */
export function arrayFilter(data: any[], query: string, fields: string[] = ['name', 'description']) {
    if (!query || query.trim() === '') {
        return data;
    }

    const txt = query.toLowerCase().trim();

    const result = data.filter(x => {
        let match = false;

        fields.forEach((field) => {
            const value = (x[field] as string)?.toLowerCase().trim();
            if (value?.includes(txt)) {
                match = true;
            }
        });

        return match;
    });

    return result;
}

/**
 * Creates a debounced function that delays invoking func
 * @param timeoutid previous execution result
 * @param action func to invoke
 * @param timeout time (ms) default = 300
 */
export function debounce(timeoutid: number, action: Function, timeout: number = 300) {
    if (timeoutid) clearTimeout(timeoutid);
    return setTimeout(action, timeout);
}


export class Deferred<T> {
    resolve: Function;
    reject: Function;
    promise: Promise<T>;

    constructor() {
        this.promise = new Promise<T>((res, rej) => {
            this.resolve = res;
            this.reject = rej;
        });
    }
}

export function localizeMe(): Promise<GeolocationPosition> {
    if (navigator.geolocation) {
        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        };
        return new Promise(function (resolve, reject) {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
    } else {
        throw Error("Browser doesn't support geolocation");
    }
}


/**
 * Check compexity of password
 * @param password 
 * @param length 
 */
export function checkComplexityPassword(password: string, length: number = 8) {
    //?! -> Not required  ?= required
    const r = new RegExp(`(?!=.*d)(?!=.*[a-z])(?!=.*[A-Z])(?=.*[!@#$%&*()]).{${length},}`);
    return r.test(password)
}

export function getDarkColor() {
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += Math.floor(Math.random() * 10);
    }
    return color;
}

/**
 * Convert a file in Base64 Format
 * @param file 
 */
export function toBase64(file: File) {
    return new Promise<string>((ok) => {
        const reader = new FileReader();

        reader.onload = (readerEvt) => {
            const binaryString = readerEvt.target.result;
            ok(btoa(binaryString as string));
        };

        reader.readAsBinaryString(file);
    });
}

/**
 * Copy to clipboard the text passed by parameter
 * @param textToCopy 
 */
export function copyToClipboard(textToCopy: string) {
    const el = document.createElement('textarea');
    el.setAttribute('readonly', '');

    el.style.position = 'absolute';
    el.style.left = '-9999px';

    el.value = textToCopy;

    document.body.appendChild(el);

    el.select();

    document.execCommand('copy');
    document.body.removeChild(el);
}

export function getFileNameFromHeader(headers){
    let filename = "";
    const disposition = headers['content-disposition'];

    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }
    return filename
}

export function fileDownload(fileName: string, fileData: string, type: string) {
    const file = new Blob([fileData], { type });
    const a = document.createElement("a"),
        url = URL.createObjectURL(file);
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }, 0);
}

/**
 * Print a DOM element selected by ID
 * @param selectorId --> example: "#main"
 */
export function printElement(selectorId: string) {
    const tmp = document.createDocumentFragment();

    const printme = document.getElementById(selectorId).cloneNode(true);

    while (document.body.firstChild) {
        // move elements into the temporary space
        tmp.appendChild(document.body.firstChild);
    }

    // put the cloned printable thing back, and print
    document.body.appendChild(printme);
    window.print();

    while (document.body.firstChild) {
        // empty the body again (remove the clone)
        document.body.removeChild(document.body.firstChild);
    }

    // re-add the temporary fragment back into the page, restoring initial state
    document.body.appendChild(tmp);
}

/**
 * Download a DOM "canvas" element as *.png file 
 * @param querySelector selector of canvas element 
 * - id -> #qr-code
 * - css selector -> canvas.qr-code
 * @param filename output file name
 */
export function downloadCanvas(querySelector: string, filename: string) {
    const canvasEl: HTMLCanvasElement = document.querySelector(querySelector)
    const url = canvasEl.toDataURL("image/png")

    const a = document.createElement('a')
    a.style.cssText = 'display: none'
    a.href = url
    a.target = '_blank'
    a.download = filename
    document.body.appendChild(a)

    a.click()

    document.body.removeChild(a);
}

/**
 * Download a DOM "svg" element as *.svg file
 * @param idSelector  selector of svg element 
 * - id -> #qr-code
 * - css selector -> svg.qr-code
 * @param filename output file name
 */
export function downloadSVG(idSelector: string, filename: string) {
    const svgEL: Node = document.getElementById(idSelector);

    const serializer = new XMLSerializer();
    let source = serializer.serializeToString(svgEL);

    //add name spaces.
    if (!source.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)) {
        source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
    }
    if (!source.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
        source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
    }

    //add xml declaration
    source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

    //convert svg source to URI data scheme.
    const url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source);

    const a = document.createElement('a')
    a.style.cssText = 'display: none'
    a.href = url
    a.target = '_blank'
    a.download = filename

    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a);
}

/**
 * To add animation event listener
 * @param element 
 * @param type 
 * @param callback 
 */
export function prefixedEvent(element: Element, type: any, callback: any) {
    const pfx = ["webkit", "moz", "MS", "o", ""];
    for (let p = 0; p < pfx.length; p++) {
        if (!pfx[p]) type = type.toLowerCase();
        element.addEventListener(pfx[p] + type, callback, false);
    }
}

/**
 * To remove animation event listener
 * @param element 
 * @param type 
 * @param callback 
 */
export function removePrefixedEvent(element: any, type: any, callback: any) {
    const pfx = ["webkit", "moz", "MS", "o", ""];
    for (let p = 0; p < pfx.length; p++) {
        if (!pfx[p]) type = type.toLowerCase();
        element.removeEventListener(pfx[p] + type, callback);
    }
}

/**
 * Get the closest number out of an array
 * @param num
 * @param arr
 */
export function closest (num, arr) {
    let curr = arr[0];
    let diff = Math.abs (num - curr);
    for (let val = 0; val < arr.length; val++) {
        const newdiff = Math.abs (num - arr[val]);
        if (newdiff < diff) {
            diff = newdiff;
            curr = arr[val];
        }
    }
    return curr;
}

/**
 * Collection of regex
 */
export const rxs = {
    EMAIL: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/,
    DATE_TIME: /[012]?[0-9]\D[0-9]{2}/,
    LETTER_AND_SPACES: /[a-zA-Z\s]+/
}

/**
 * Lazy loader for script 
 * @param src 
 */
export function loadScript(src: string) {
    const s = document.createElement('script');
    s.type = "text/javascript";
    s.async = true;
    s.src = src;

    return new Promise((resolve) => {
        s.addEventListener('load', (evt) => {
            console.debug("Evento load");
            resolve(evt);
        });

        const head = document.getElementsByTagName('head')[0];
        head.appendChild(s);
    });

}

/**
 * OnDrop event handler, return a single file if present.
 * @param event OnDrop event 
 */
export function onDropSingleFile(event: DragEvent) {
    if (event.dataTransfer.items) {
        const item = event.dataTransfer.items[0];
        if (item.kind === 'file') {
            return item.getAsFile();
        }

    } else {
        return event.dataTransfer.files[0];
    }
}

function isTouchDevice() {
    let hasTouchScreen = false;

    if ("maxTouchPoints" in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
        hasTouchScreen = (navigator as any).msMaxTouchPoints > 0;
    } else {
        const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
        if (mQ && mQ.media === "(pointer:coarse)") {
            hasTouchScreen = !!mQ.matches;
        } else if ('orientation' in window) {
            hasTouchScreen = true; // deprecated, but good fallback
        } else {
            // Only as a last resort, fall back to user agent sniffing
            const UA = (navigator as any).userAgent;
            hasTouchScreen = (
                /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
            );
        }
    }

    return hasTouchScreen;
}

/**
 * Log semantic version of the APP. 
 * You can modify the version in the package.json file.
 * The version is copied in process.env in vue.config file.
 */
export function logAppVersion() {
    console.info(
        `%cVERSION`,
        "background-color:#4aa935; color:white; padding: 0 3rem; font-weight: bold;",
        process.env.VUE_APP_VERSION
    );
}

export function removeNullValues(obj) {
    Object.keys(obj).forEach(key => {
        if (obj[key] === null) {
            delete obj[key];
        }
    });
}


export function minMax(items) {
    return items.reduce((acc, val) => {
        acc[0] = ( acc[0] === undefined || val < acc[0] ) ? val : acc[0]
        acc[1] = ( acc[1] === undefined || val > acc[1] ) ? val : acc[1]
        return acc;
    }, []);
}

export async function calcDrivingDistance(x: {lat: number, lng:number}, y: {lat: number, lng:number}): Promise<number>{
    return new Promise((resolve, reject) => {
        // Specify the latitude and longitude coordinates for origin and destination
        const originLatLng = new google.maps.LatLng(x.lat, x.lng);
        const destinationLatLng = new google.maps.LatLng(y.lat, y.lng);

        // Create a DirectionsService object
        const directionsService = new google.maps.DirectionsService();

        // Set up the DirectionsRequest
        const request = {
            origin: originLatLng,
            destination: destinationLatLng,
            travelMode: google.maps.TravelMode.DRIVING, // Specify the desired travel mode
            unitSystem: google.maps.UnitSystem.IMPERIAL, // Specify the unit system (metric or imperial)
            avoidHighways: false, // Set to true to avoid highways (optional)
            avoidTolls: false // Set to true to avoid toll roads (optional)
        };

        // Send the request to the DirectionsService
        directionsService.route(request, (response, status) => {
            if (status === 'OK') {
                const route = response.routes[0];
                let distance = 0;

                // Calculate the total distance by summing up the distances of each step
                route.legs.forEach((leg) => {
                    leg.steps.forEach((step) => {
                        distance += step.distance.value;
                    });
                });

                resolve(distance * 0.000621371); // Convert meters to miles
            } else {
                reject(new Error('Directions request failed.'));
            }
        });
    });
}

export function calcDistance(x: {lat: number, lng:number}, y: {lat: number, lng:number}) {
    const R = 3958.8; // Radius of the Earth in miles

    const rlat1 = x.lat * (Math.PI / 180); // Convert degrees to radians
    const rlat2 = y.lat * (Math.PI / 180); // Convert degrees to radians
    const difflat = rlat2 - rlat1; // Radian difference (latitudes)

    const difflon = (y.lng - x.lng) * (Math.PI / 180); // Radian difference (longitudes)

    const d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
    return d;
}
