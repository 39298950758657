import { AccessesBySection, UserRoleEnum } from '@/model/enums/UserRoleEnum';
import { RouteRecordRaw } from 'vue-router';
import { ProjectsRoutesEnum } from './ProjectsRoutesEnum';

const ProjectsPage   = () => import(/* webpackChunkName: "Projects-module" */ '../pages/projects/ProjectsPage.vue');
const ProjectPage    = () => import(/* webpackChunkName: "Projects-module" */ '../pages/project/ProjectPage.vue');
const ProjectData   = () => import(/* webpackChunkName: "Projects-module" */ '../pages/project/ProjectData/ProjectData.vue');
const ProjectKPI   = () => import(/* webpackChunkName: "Projects-module" */ '../pages/project/ProjectKPI/ProjectKPI.vue');
const ProjectActivities   = () => import(/* webpackChunkName: "Projects-module" */ '../pages/project/ProjectActivities/ProjectActivities.vue');
const ProjectShifts   = () => import(/* webpackChunkName: "Projects-module" */ '../pages/project/ProjectShifts/ProjectShifts.vue');
const ProjectTickets   = () => import(/* webpackChunkName: "Projects-module" */ '../pages/project/ProjectTickets/ProjectTickets.vue');

const routes:  Array<RouteRecordRaw> = [
    {
        path: '/projects',
        name: ProjectsRoutesEnum.PROJECT_LIST,
        component: ProjectsPage,
        meta	 : {
          tabTitle: 'Project List',
          breadCrumbItems: [
            { label: 'Project List' },
          ],
          roles: AccessesBySection.PROJECTS
        }
    },
    {
        path: '/projects/:projectId',
        props: true,
        component: ProjectPage,
        meta: {
            breadCrumbItems: [
                {
                    label: 'Projects',
                    to: {name: ProjectsRoutesEnum.PROJECT_LIST}
                },
                {label: 'Project details'},
            ],
            roles: AccessesBySection.PROJECTS
        },
        children: [
            {
                path      : '',
                name: ProjectsRoutesEnum.PROJECT_DETAIL,
                component : ProjectData,
                meta: {
                    tabTitle: 'Project Sheet'
                }
            },
            {
                path      : 'kpi',
                name: ProjectsRoutesEnum.PROJECT_KPI,
                component : ProjectKPI,
                meta: {
                    tabTitle: 'Project KPI'
                }
            },
            {
                path      : 'tickets',
                name: ProjectsRoutesEnum.PROJECT_TICKETS,
                component : ProjectTickets,
                props: true,
                meta: {
                    tabTitle: 'Project Tickets'
                }
            },
            {
                path      : 'activities',
                name: ProjectsRoutesEnum.PROJECT_ACTIVITIES,
                component : ProjectActivities,
                meta: {
                    tabTitle: 'Project Activities'
                }
            },
            {
                path      : 'shifts',
                name: ProjectsRoutesEnum.PROJECT_SHIFTS,
                component : ProjectShifts,
                meta: {
                    tabTitle: 'Project Shifts'
                }
            },
        ]
    }
];

export { routes };
export { ProjectsRoutesEnum };
