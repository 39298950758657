import { InjectionKey } from "vue";
import { createStore, createLogger, Store, useStore } from "vuex";

import { AppState } from "./types";

export const key_store: InjectionKey<Store<AppState>> = Symbol()

export function useAppStore(){
  return useStore(key_store);
}

export default createStore<AppState>({
  strict: process.env.NODE_ENV !== 'production',

  state: {
    counter: 0
  },
  mutations: {
    increment(state){
      state.counter++;
    },

    decrement(state){
      state.counter--;
    }
  },
  
  plugins: process.env.NODE_ENV !== 'production' ? [ createLogger() ] : []
})
