import {AccessesBySection, UserRoleEnum} from '@/model/enums/UserRoleEnum';
import { RouteRecordRaw } from 'vue-router';
import { NotificationsRoutesEnum } from './NotificationsRoutesEnum';

const NotificationsPage   = () => import(/* webpackChunkName: "Notifications-module" */ '../pages/notifications/NotificationsPage.vue');

const routes:  Array<RouteRecordRaw> = [
    {
        path      : '/notifications',
        name      : NotificationsRoutesEnum.NOTIFICATIONS_LIST,
        component : NotificationsPage,
        meta: {
            tabTitle: "Notifications",
			breadCrumbItems: [
				{label: 'Notifications'},
			],
            roles: AccessesBySection.NOTIFICATION_CENTER
		}
    }
];

export { routes };
export { NotificationsRoutesEnum };
