export enum CompaniesRoutesEnum {

    CLIENT_PREFIX = "CLIENT",
    BROKER_PREFIX = "BROKER",
    CUSTOMER_PREFIX = "CUSTOMER",

    BROKER_INVOICES = 'BROKER_INVOICES',
    CLIENT_INVOICES = 'CLIENT_INVOICES',
    CUSTOMER_INVOICES = 'CUSTOMER_INVOICES',

    BROKERS_LIST = 'BROKERS_LIST',
    BROKER_DETAIL = 'BROKER_DETAIL',

    CLIENTS_LIST = 'CLIENTS_LIST',
    CLIENT_DETAIL = 'CLIENT_DETAIL',

    CUSTOMERS_LIST = 'CUSTOMERS_LIST',
    CUSTOMER_DETAIL = 'CUSTOMER_DETAIL'
}