import { RouteRecordRaw } from 'vue-router';
import {InvoicesRoutesEnum} from "@/modules/invoices/router/InvoicesRoutesEnum";
import {AccessesBySection} from "@/model/enums/UserRoleEnum";

const InvoicesPage  = () => import(/* webpackChunkName: "Invoices-module" */ '../pages/invoices/InvoicesPage.vue');

const routes:  Array<RouteRecordRaw> = [
    {
        path: '/invoices',
        name: InvoicesRoutesEnum.INVOICES_LIST,
        component: InvoicesPage,
		meta	 : {
			tabTitle: "Invoices List",
			breadCrumbItems: [
				{label: 'Invoices List'},
			],
			roles: AccessesBySection.LEDGERS
		}
    }
];

export { routes };
export { InvoicesRoutesEnum };
