import { LoginData } from '@/model/api/Auth';
import { User } from '@/model/api/User';
import { localStorageSVC } from '@/plugins/LocalStorage-plugin';
import { AppState } from '@/store/types';
import {authService} from '@services/auth.service';
import { ActionTree } from 'vuex';
import { AuthState } from './state';

export interface Actions {
    login(data: LoginData): Promise<any>;

    getMe(): Promise<any>;

    updateMe(user: User): void;
}


const actions: ActionTree<AuthState, AppState> = {
    async login({ dispatch, commit }, data: LoginData) {
        try {
            commit('setLoading', true);
            const response = await authService.login(data);

            //SALVO IL TOKEN
            localStorageSVC.token = response.access_token;

            //CHIEDO I DATI DELL'UTENTE
            return dispatch('getMe');
        } catch (e) {
            console.error(e);
            // handleError(commit, e);
        } finally {
            commit('setLoading', false);
        }

    },

    async getMe({ commit }) {
        const response = await authService.me();
        commit('setMe', response);
        return response;
    },

    async updateMe({ commit }, me: User) {
        try {
            const response = await authService.updateMe(me);
            commit('setMe', response);
            return response;
        } catch (e) {
            console.error(e);
            // handleError(commit, e);
        }
    },
}

export default actions;