import {AccessesBySection, UserRoleEnum} from '@/model/enums/UserRoleEnum';
import { RouteRecordRaw } from 'vue-router';
import { ContractsRoutesEnum } from './ContractsRoutesEnum';

const ContractsPage  = () => import(/* webpackChunkName: "Contracts-module" */ '../pages/contracts/ContractsPage.vue');
const ContractPage   = () => import(/* webpackChunkName: "Contracts-module" */ '../pages/contract/ContractPage.vue');

const routes:  Array<RouteRecordRaw> = [
    {
        path: '/contracts',
        name: ContractsRoutesEnum.CONTRACTS_LIST,
        component: ContractsPage,
		meta	 : {
			tabTitle: "Contracts List",
			breadCrumbItems: [
				{label: 'Contracts List'},
			],
			roles: AccessesBySection.CONTRACTS
		}
    },
	{
		path     : '/contracts/:contractId',
		name     : ContractsRoutesEnum.CONTRACTS_DETAIL,
		props    : true,
		component: ContractPage,
		meta	 : {
			tabTitle: "Contract detail",
			breadCrumbItems: [
				{label: 'Contracts List', to: { name: ContractsRoutesEnum.CONTRACTS_LIST }},
				{label: 'Contract detail'},
			],
			roles: AccessesBySection.CONTRACTS
		}
	},
];

export { routes };
export { ContractsRoutesEnum };
