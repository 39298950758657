import { defineRule } from 'vee-validate';

import { 
  required, 
  email, 
  min, 
  confirmed
} from '@vee-validate/rules';

defineRule('required', (x : any) => {
  if (required(x)) {
    return true;
  } else {
    return "validation.required";
  }
});

defineRule('email', (x : any) => {
  if (email(x)) {
    return true;
  } else {
    return "validation.email";
  }
});

defineRule('min', (x: string, y: any) => {
  if (min(x, y)) {
    return true;
  } else {
    return "validation.min_length";
  }
});

defineRule('confirmPwd', (x: string, y: any) => {
  if (confirmed(x, y)) {
    return true;
  } else {
    return "validation.confirm_pwd";
  }
});

/*
// ALL RULES
Object.keys(rules).forEach(rule => {
  defineRule(rule, AllRules[rule]);
});
*/