import { CompanyTypeEnum } from '@/model/enums/CompanyTypeEnum';
import {AccessesBySection, UserRoleEnum} from '@/model/enums/UserRoleEnum';
import { RouteRecordRaw } from 'vue-router';
import { CompaniesRoutesEnum } from './CompaniesRoutesEnum';

const ListPage = () => import(/* webpackChunkName: "Companies-module" */ '../pages/ListPage.vue');
const CompanyPage = () => import(/* webpackChunkName: "Companies-module" */ '../pages/companyPage.vue');
const DetailPage = () => import(/* webpackChunkName: "Companies-module" */ '../pages/companyData/DetailPage.vue');
const CompanyInvoices = () => import(/* webpackChunkName: "Companies-module" */ '../pages/companyInvoices/CompanyInvoices.vue');

const routes: Array<RouteRecordRaw> = [
	{
		path: '/clients',
		name: CompaniesRoutesEnum.CLIENTS_LIST,
		component: ListPage,
		props: { type: CompanyTypeEnum.CLIENT },
		meta: {
			tabTitle: "Clients List",
			breadCrumbItems: [
				{ label: 'Clients List' },
			],
			roles: AccessesBySection.CLIENTS
		}
	},


	{
		path     : '/clients/:companyId',
		props    : true,
		component: CompanyPage,
		meta	 : {
			breadCrumbItems: [
				{label: 'Clients List', to: { name: CompaniesRoutesEnum.CLIENTS_LIST }},
				{label: 'Client detail'},
			],
			tabTitle: "Client detail",
			roles: AccessesBySection.CLIENTS
		},
		children: [
			{
				path      : '',
				name      : CompaniesRoutesEnum.CLIENT_DETAIL,
				component : DetailPage,
			},
			{
				path      : 'invoices',
				name      : CompaniesRoutesEnum.CLIENT_INVOICES,
				component : CompanyInvoices,
			},
		]
	},

	{
		path: '/brokers',
		name: CompaniesRoutesEnum.BROKERS_LIST,
		component: ListPage,
		props: { type: CompanyTypeEnum.BROKER },
		meta: {
			tabTitle: "Brokers List",
			breadCrumbItems: [
				{ label: 'Brokers List' },
			],
			roles: AccessesBySection.BROKERS
		}
	},

	{
		path     : '/brokers/:companyId',
		props    : true,
		component: CompanyPage,
		meta	 : {
			breadCrumbItems: [
				{label: 'Brokers List', to: { name: CompaniesRoutesEnum.BROKERS_LIST }},
				{label: 'Broker detail'},
			],
			tabTitle: "Broker detail",
			roles: AccessesBySection.BROKERS
		},
		children: [
			{
				path      : '',
				name      : CompaniesRoutesEnum.BROKER_DETAIL,
				component : DetailPage,
			},
			{
				path      : 'invoices',
				name      : CompaniesRoutesEnum.BROKER_INVOICES,
				component : CompanyInvoices,
			},
		]
	},

	{
		path: '/customers',
		name: CompaniesRoutesEnum.CUSTOMERS_LIST,
		component: ListPage,
		props: { type: CompanyTypeEnum.CUSTOMER },
		meta: {
			tabTitle: "Customers List",
			breadCrumbItems: [
				{ label: 'Customers List' },
			],
			roles: AccessesBySection.CUSTOMERS
		}
	},

	{
		path     : '/customers/:companyId',
		props    : true,
		component: CompanyPage,
		meta	 : {
			breadCrumbItems: [
				{label: 'Customers List', to: { name: CompaniesRoutesEnum.CUSTOMERS_LIST }},
				{label: 'Customer detail'},
			],
			tabTitle: "Customer detail",
			roles: AccessesBySection.CUSTOMERS
		},
		children: [
			{
				path      : '',
				name      : CompaniesRoutesEnum.CUSTOMER_DETAIL,
				component : DetailPage,
			},
			{
				path      : 'invoices',
				name      : CompaniesRoutesEnum.CUSTOMER_INVOICES,
				component : CompanyInvoices,
			},
		]
	}
];

export { routes };
export { CompaniesRoutesEnum };
