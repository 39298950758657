import {AccessesBySection, UserRoleEnum} from '@/model/enums/UserRoleEnum';
import { RouteRecordRaw } from 'vue-router';
import {CustomShiftsRoutesEnum} from "./CustomShiftsRoutesEnum";

const CustomShiftsPage   = () => import(/* webpackChunkName: "Shifts-module" */ '../pages/custom-shifts/CustomShiftsPage.vue');
const CustomShiftPage   = () => import(/* webpackChunkName: "Shifts-module" */ '../pages/custom-shift/CustomShiftPage.vue');

const routes:  Array<RouteRecordRaw> = [
    {
        path      : '/custom-shifts',
        name      : CustomShiftsRoutesEnum.SHIFTS_LIST,
        component : CustomShiftsPage,
        meta: {
            tabTitle: "App Work Requests",
			breadCrumbItems: [
				{label: 'App Work Requests'},
			],
            roles: AccessesBySection.APP_WRS
		}
    },
    {
        path: '/custom-shifts/:shiftId',
        props: true,
        name      : CustomShiftsRoutesEnum.SHIFTS_DETAIL,
        component: CustomShiftPage,
        meta: {
            breadCrumbItems: [
                {
                    label: 'App Work Requests',
                    to: {name: CustomShiftsRoutesEnum.SHIFTS_LIST}
                },
                {label: 'App Work Request details'},
            ],
            roles: AccessesBySection.APP_WRS
        }
    }
];

export { routes };
export { CustomShiftsRoutesEnum };
