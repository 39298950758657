import { configuration } from "@plugins/Config-plugin";
import { localStorageSVC } from "@plugins/LocalStorage-plugin";

import { Manager } from "socket.io-client";

const socketManager = new Manager(configuration.socketUrl, {
    reconnectionDelayMax: 10000,
});

export  function connectSocket(namespace: string) {

    const token = localStorageSVC.token; 

    const socket = socketManager.socket(namespace, {
        auth: { token }
    });

    socket.on("connect_error", (err) => {
        console.log("connect_error" + err.message);
    });

    socket.on("auth", (success) => {
        console.log("auth" + success);
        if (success)
            socket.emit("watch_gps")
    });

    return socket; 
}

export default socketManager;